body {
  font-family: "Poppins" !important;
}

.aspectRatio {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.headerRow {
  background-color: #0c4da9;
  padding: 5px;
}

.menuIcon {
  width: 23%;
}

.posHeadLabel {
  font-weight: normal;
  font-size: 1.2vw;
  letter-spacing: 0.36px;
  color: #ffffff;
  opacity: 1;
}

.timeFmt {
  font-size: 1.1vw;
  color: #ffffff;
  margin-left: 2%;
  letter-spacing: 0.36px;
  opacity: 1;
}

.tillName {
  font-size: 1.1vw;
  color: #ffffff;
  margin-left: 2%;
  letter-spacing: 0.36px;
  opacity: 1;
}

.right {
  text-align: right;
}

.time {
  color: #ffffff;
  text-align: right;
  font-size: 1.1vw;
  letter-spacing: 0.36px;
  opacity: 1;
}

.posLogoIcon {
  width: 30%;
}

.registrationFormDiv {
  width: 100% !important;
  height: 100% !important;
  padding: 5vh 3vh 0vh;
}

.registrationCard {
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 20px !important;
  border: 1px solid #ebeded !important;
  opacity: 1;
}

.registrationLogo {
  height: 40px;
  margin-top: -45px;
}

.registrationFormItem {
  margin-bottom: 10px;
}

.registrationLabel {
  font-size: 12px !important;
  font-weight: normal;
  font-family: "Poppins";
  color: #000000;
  letter-spacing: 0.4px;
  text-align: left;
  opacity: 1;
}

.registrationInputReadOnly, .registrationInputReadOnly:hover, .registrationInputReadOnly:focus {
  background-color: #00000029;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.registrationInput,
.registrationInput:hover,
.registrationInput:focus {
  border: 1px solid #d9d9d9;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  opacity: 1;
}

.registrationInput::placeholder {
  font-size: 12px !important;
  font-weight: normal;
  font-family: "Poppins";
}

.category {
  font-size: 12px !important;
}

.registerButton,
.registerButton:hover, .registerButton:focus {
  width: 15%;
  font-size: 14px;
  font-weight: normal;
  font-family: Poppins;
  background-color: #0c4da9;
  color: #ffffff;
  border: 1px solid #0c4da9;
  border-width: 0;
  border-radius: 30px;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  float: right;
}

.ant-form-item-explain {
  font-size: 12px;
}
